

@media (min-width: 320px) and (max-width: 480px) {
  #sidebar_wrap {
    display: none;
  }
}

/* IPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

}

/* Small Screens, Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

}
/* Desktops, Large Screens */
@media (min-width: 1025px) and (max-width: 1200px) {

}
/* Extra Large Screens, TV */
@media (min-width: 1201px) {
  #sidebar_wrap {
    width: 30vw;
    height: 90vh;
    z-index: 30;
    position: fixed;
    background: #212121;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  }

}