.profile-tabs {
  width: 100vw;
  bottom: 0;
  position: absolute;
}

.nav-item {
  width: 50vw;
}



