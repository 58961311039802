body {
  margin: 0;
  font-family: "fieldwork", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

:root {
  --amplify-primary-color: #ff6347;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
  --amplify-font-family: "fieldwork", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Styles */

h1 {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0;
}

h2 {
  font-size: 32px;
  font-weight: 600;
}

h3  {
  font-size: 24px;
  font-weight: 500;
}


h4 {
  font-size: 20px;
  font-weight: 400;
}

h5 {
  font-size: 12px;
  font-weight: 400;
}

p {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5em;
}


.col_1 {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.col_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.col_3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.col_4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}

.col_5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
}

button {
  outline: none;
}

a {
  text-decoration: none;
}

.gmnoprint {
  display: none
}

#sidebar_wrap::-webkit-scrollbar {
  display: none;
}