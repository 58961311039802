

@media (min-width: 320px) and (max-width: 480px) {
  .top_nav_wrap {
    padding: 20px;
    position: absolute;
    top: 0;
    background: #222222;
    z-index: 6000;
  }

  .top_nav_wrap Button {
    margin-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    display: none;
  }

  #exampleSearch {
    display: none;
  }
}

/* IPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

}

/* Small Screens, Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

}
/* Desktops, Large Screens */
@media (min-width: 1025px) and (max-width: 1200px) {

}
/* Extra Large Screens, TV */
@media (min-width: 1201px) {
  .top_nav_wrap {
    padding: 20px;
    position: absolute;
    top: 0;
    background: #222222;
    z-index: 6000;
  }

  .top_nav_wrap Button {
    margin-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }

  #exampleSearch {
    margin-top: 10px;
    width: 600px;
  }
}