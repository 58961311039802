#list_view_wrap {
  z-index: 5000;
  background-color: #222222;
  color: white;
  border-radius: 100%;
  height: 58px;
  width: 58px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  .fa-lg {
    margin-top: 18px;
  }
}



@media (min-width: 1025px) {
  #list_view_wrap {
    display: none;
  }
}