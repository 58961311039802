.card_body .btn {
  border-color: white;
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  color: white;
}

#img_wrap {
  background: rgba(0, 0, 0, 0.1);
}

.card_list {
  text-align: left;
  .card_wrap {
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: transparent;
    .card_body .col_2 {
      grid-column-gap: 10px;
    }
  }
}

.heart_icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .card_body {
    width: 90vw;
    background: #333333;
    border-radius: 10px;
  }
}

/* IPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

}

/* Small Screens, Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

}
/* Desktops, Large Screens */
@media (min-width: 1025px) and (max-width: 1200px) {

}
/* Extra Large Screens, TV */
@media (min-width: 1201px) {
  .card_list {
    margin: 20px;
    background-color: transparent;
    border-radius: 10px;
    .card_wrap {
      margin-bottom: 15px;
      border-radius: 10px;
      background-color: transparent;
      border-color: transparent;
      .card-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .divider {
      width: 100%;
      height: 2px;
      background-color: #333333;
    }
  }
}