
.authModal {
  position: absolute;
  top:      0;
  left:     0;
  height:   100vh;
  width:    100vw;

  display:        grid;
  align-items:    center;
  justify-items:  center;

  background-color: rgba(0,0,0,0.5);
  z-index: 3000;
  /*animation: darken .2s;*/

}
/*.Modal .content {*/

/*    background-color:  white;*/
/*    box-shadow: 0 2rem 4rem ;*/
/*    border-radius:     3px;*/

/*    overflow:   hidden;*/
/*    opacity:    1;*/
/*    animation:  expand .2s;*/
/*    z-index:    10;*/

/*}*/

/*@keyframes expand {*/
/*    from {transform: translate(0, 200%) scale(.5);}*/
/*    to {transform: translate(0, 0) scale(1);}*/
/*}*/

/*@keyframes darken {*/
/*    from {background-color: transparent}*/
/*    to {background-color: rgba(0,0,0,0.5);}*/
/*}*/
