.bottom-nav {
  background-color: #222222;
  box-shadow: 0 -1px 2px 0 rgba(0,0,0,0.75);
  position: absolute;
}

nav {
  padding: 0;
  position: absolute;
  z-index: 3;
  bottom: 0;
}

.nav-link {
  color: lightgrey;
}

.nav-link:hover {
  color: #EB5757;
}

.active {
  color: #EB5757;
}


/* Phones, Mobile*/
@media (min-width: 320px) and (max-width: 480px) {
  .bottom-nav {
    background-color: #222222;
    box-shadow: 0 -1px 2px 0 rgba(0,0,0,0.75);
    position: absolute;
    width: 100vw;
  }
}

/* IPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

}

/* Small Screens, Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

}

/* Desktops, Large Screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  nav {
    width: 50vw;
  }
}

/* Extra Large Screens, TV */
@media (min-width: 1201px) {
  #bottom_nav {
    display: none;
  }
}


