.deats_container {
}

.info_wrap .col_3 {
    width: 100%;
}

.info_wrap {
    height: 60vh;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    background-color: #333333;
    z-index: 30;
}