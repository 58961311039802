.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #212121;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 250px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 10px;
  color: #222222;
}

.locate {
  position: absolute;
  top: 2rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
  outline: 0;
}

.locate img {
  width: 30px;
  cursor: pointer;
}

