.profile-header {
  background: #222222;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 240px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.75);
  position: absolute;
}

.profile-header-wrap {
}

.header-top {
  padding-top: 15px;
}

.icon-container {
  text-align: right;
  padding-right: 15px;
}

.profile-img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background: darkgrey;
  margin: 15px auto 15px auto;
}